<template>
  <Layout>
    <template #left>
      <SignupStepper :step="4" :showPropertyStep="true" />
    </template>
    <template #main>
      <PropertyInformationForm v-model="propertyInformation" />
      <div class="flex column gap-1">
        <Button class="mt-1" variant="secondary" :loading="loading" @click="onSave">
          {{ t('signup.onboarding.title') }}
        </Button>
      </div>
    </template>
    <template #footer>
      <SignupStepper :step="4" mobile />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { Button, Layout, PropertyInformationForm, SignupStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findPropertyById, updatePropertyCoreInformation } from '@/modules/property/application'
import type { PropertyCoreInformation } from '@/modules/property/domain/propertyCoreInformation'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const propertyInformation = ref<PropertyCoreInformation | undefined>()
const loading = ref(false)

track(AnalyticsEvent.LandlordSignupStartPropertyData)

onMounted(async () => {
  const property = await findPropertyById(id)
  propertyInformation.value = { ...property.coreInformation }
})

async function onSave() {
  if (!propertyInformation.value) return

  loading.value = true
  try {
    await updatePropertyCoreInformation(id, propertyInformation.value)
    track(AnalyticsEvent.LandlordSignupSubmitPropertyData)
  } catch {}
  loading.value = false
  router.push({ name: 'properties', query: { accountSuccess: id } })
}
</script>

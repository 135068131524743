<template>
  <ApplicationCompletedModal v-if="showDocumentationCompletedModal" />
  <Layout>
    <template #left>
      <DocumentationHint :mobile="false" />
    </template>
    <template #main>
      <h2>{{ t('application.documentation.title') }}</h2>
      <p class="mt-1">
        {{ t('application.documentation.description') }}
      </p>
      <DocumentationHint :mobile="true" class="mt-1" />
      <template v-if="tenants">
        <TenantCard
          v-for="(tenant, index) in tenants"
          class="mt-2"
          ref="tenantCards"
          v-model="tenants[index]"
          :canEditEmail="index !== 0"
        />
        <AddTenant class="mt-2" :loading="adding" @click="onAdd" />
      </template>
      <template v-else>
        <CardSkeleton class="mt-2" />
      </template>
    </template>
    <template #footer>
      <DocumentationFooter
        v-if="!showDocumentationCompletedModal"
        :loading="loading"
        :canSend="isApplicationCompleted"
        @exit="onSaveAndExit"
        @send="onSend"
      />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import {
  AddTenant,
  ApplicationCompletedModal,
  CardSkeleton,
  DocumentationFooter,
  DocumentationHint,
  Layout,
  TenantCard
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { submitApplication } from '@/modules/application/application'
import { createTenant, findAllTenants } from '@/modules/tenant/application'
import { isTenantCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { router } from '@/router'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const tenants = ref<Nullable<Tenant[]>>(null)
const loading = ref(false)
const adding = ref(false)
const tenantCards = ref<(typeof TenantCard)[]>([])
const showDocumentationCompletedModal = ref(false)

track(AnalyticsEvent.TenantApplicationDocuments)

onMounted(async () => {
  tenants.value = await findAllTenants()
  if (isApplicationCompleted.value) {
    router.push({ name: 'applications' })
  }
})

const isApplicationCompleted = computed(() => tenants.value?.every(isTenantCompleted) ?? false)

async function onAdd() {
  if (adding.value) return

  adding.value = true
  track(AnalyticsEvent.TenantApplicationAddTenant)
  await createTenant()
  tenants.value = await findAllTenants()
  adding.value = false
}

async function saveAll() {
  for (const tenantCard of tenantCards.value) {
    await tenantCard.save()
  }
}

async function onSend() {
  track(AnalyticsEvent.TenantApplicationSendDocuments)
  loading.value = true
  try {
    await saveAll()
    await submitApplication()
    track(AnalyticsEvent.TenantApplicationCompleted)
    showDocumentationCompletedModal.value = true
  } catch {
    loading.value = false
  }
}

async function onSaveAndExit() {
  track(AnalyticsEvent.TenantApplicationSaveDocuments)
  loading.value = true
  try {
    await saveAll()
    router.push({ name: 'applications' })
  } catch {
    loading.value = false
  }
}
</script>

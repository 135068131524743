<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <Layout>
    <template #left>
      <PropertyStepper :property="property" :landlord="landlord" />
    </template>
    <template #main>
      <PaymentInformationForm v-model="paymentInformation" />
    </template>
    <template #footer>
      <PropertyStepper mobile :property="property" :landlord="landlord" />
      <OnboardingFooter
        v-if="!showOnboardingCompletedModal"
        @continue="onContinue"
        @exit="onExit"
        :step="6"
        :isLoading="loading"
        :isCompleted="isOnboardingCompleted"
      />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import {
  Layout,
  OnboardingCompletedModal,
  OnboardingFooter,
  PaymentInformationForm,
  PropertyStepper
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { hasLandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import {
  findPropertyById,
  finishPropertyOnboarding,
  updatePropertyPaymentInformation
} from '@/modules/property/application'
import { isPropertyComplete, type Property } from '@/modules/property/domain/property'
import type { PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { router } from '@/router'
import { computed, onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const paymentInformation = ref<PropertyPaymentInformation | undefined>()
const loading = ref(false)
const showOnboardingCompletedModal = ref(false)

track(AnalyticsEvent.LandlordPropertyRegisterStartPlan)

const isOnboardingCompleted = computed(() => {
  if (!paymentInformation.value || !property.value || !landlord.value) {
    return false
  }
  return (
    hasLandlordDocuments(landlord.value) &&
    isPropertyComplete({ ...property.value, paymentInformation: paymentInformation.value })
  )
})

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  paymentInformation.value = { ...property.value.paymentInformation }
})

async function save() {
  if (!paymentInformation.value || !property.value) return

  loading.value = true
  try {
    await updatePropertyPaymentInformation(id, paymentInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitPlan)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  await router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  if (isOnboardingCompleted.value) {
    await finishPropertyOnboarding(id)
    track(AnalyticsEvent.LandlordOnboardingCompleted)
    showOnboardingCompletedModal.value = true
  } else {
    await router.push({ name: 'resumeOnboarding' })
  }
}
</script>

<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="left-content">
        <img src="@/assets/images/vivara_logo_gray.png" alt="Logo" class="footer-logo" />
        <p class="footer-text-left">{{ $t('infoFooter.info1') }}</p>
      </div>
      <p class="footer-text-right">{{ $t('infoFooter.info2') }}</p>
    </div>
  </footer>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.footer {
  background-color: $grey100;
  border-top: 1px solid $grey200;
  padding: $paddingS 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 4rem;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 $paddingXL;

  .left-content {
    display: flex;
    align-items: center;
  }

  .footer-logo {
    max-width: 100px;
    height: 24px;
    margin-right: $paddingS;
  }

  .footer-text-left {
    font-size: 14px;
    font-weight: normal;
    color: rgba(11, 11, 11, 0.25);
  }

  .footer-text-right {
    font-size: 14px;
    font-weight: normal;
    color: rgba(11, 11, 11, 0.25);
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 $paddingL;

    .left-content {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      img {
        display: none;
      }
    }
    .footer-logo {
      margin-bottom: $paddingXS;
    }

    .footer-text-right,
    .footer-text-left {
      font-size: 0.75rem;
      width: 100%;
    }
  }
}
</style>

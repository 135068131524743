<template>
  <Layout>
    <template #left>
      <PropertyStepper :property="property" :landlord="landlord" />
    </template>
    <template #main>
      <FormSkeleton />
    </template>
    <template #footer>
      <PropertyStepper mobile :property="property" :landlord="landlord" />
      <OnboardingFooter :step="0" :isLoading="loading" disabled />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { FormSkeleton, Layout, OnboardingFooter, PropertyStepper } from '@/components'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById } from '@/modules/property/application'
import {
  hasBasicInformation,
  hasCoreInformation,
  hasEquipmentInformation,
  hasPropertyDocuments,
  hasPropertyImages,
  type Property
} from '@/modules/property/domain/property'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const loading = ref(false)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  if (!hasCoreInformation(property.value)) {
    router.replace({ name: 'coreInformation' })
  } else if (!hasBasicInformation(property.value)) {
    router.replace({ name: 'basicInformation' })
  } else if (!hasEquipmentInformation(property.value)) {
    router.replace({ name: 'equipmentInformation' })
  } else if (!hasPropertyImages(property.value)) {
    router.replace({ name: 'propertyPhotos' })
  } else if (!hasPropertyDocuments(property.value)) {
    router.replace({ name: 'propertyDocuments' })
  } else {
    router.replace({ name: 'paymentInformation' })
  }
})
</script>

import type { PropertyBasicInformation } from './propertyBasicInformation'
import type { PropertyCoreInformation } from './propertyCoreInformation'
import type { PropertyDocuments } from './propertyDocuments'
import type { PropertyEquipmentInformation } from './propertyEquipmentInformation'
import type { PropertyImages } from './propertyImages'
import type { PropertyPaymentInformation } from './propertyPaymentInformation'

export interface Property {
  basicInformation: PropertyBasicInformation
  coreInformation: PropertyCoreInformation
  documents: PropertyDocuments
  equipmentInformation: PropertyEquipmentInformation
  id: string
  images: PropertyImages
  listed: boolean
  paymentInformation: PropertyPaymentInformation
}

export function hasBasicInformation(property: Property): boolean {
  const { bathrooms, bedrooms, pets, propertyCondition, propertyType } = property.basicInformation
  return Boolean(bathrooms && bedrooms && propertyCondition && propertyType) && pets !== null
}

export function hasCoreInformation(property: Property): boolean {
  const { availabilityType, builtSize, catastralReference, floor, street, rent } = property.coreInformation
  return Boolean(rent && availabilityType) && (Boolean(catastralReference) || Boolean(builtSize && floor && street))
}

export function hasEquipmentInformation(property: Property): boolean {
  return property.equipmentInformation.furniture !== null
}

export function hasPropertyDocuments(property: Property): boolean {
  return (
    property.documents.energyCertificate.length > 0 &&
    property.documents.habitabilityCertificate.length > 0 &&
    property.documents.utilityBill.length > 0
  )
}

export function hasPropertyImages(property: Property): boolean {
  return property.images.files.length > 0 || Boolean(property.images.needsPhotoService)
}

export function hasPaymentInformation(property: Property): boolean {
  return property.paymentInformation.plan !== null
}

export function isPropertyComplete(property: Property): boolean {
  return (
    hasBasicInformation(property) &&
    hasCoreInformation(property) &&
    hasEquipmentInformation(property) &&
    hasPropertyDocuments(property) &&
    hasPropertyImages(property) &&
    hasPaymentInformation(property)
  )
}

<template>
  <div class="card flex column gap-1" ref="card">
    <InputField
      v-model="model.name"
      :error="errors['name']"
      :label="`* ${t('forms.name')}`"
      :placeholder="t('forms.name')"
      type="text"
      data-test-id="nameInput"
    />
    <InputField
      v-model="model.surname"
      :error="errors['surname']"
      :label="`* ${t('forms.surname')}`"
      :placeholder="t('forms.surname')"
      type="text"
      data-test-id="surnameInput"
    />
    <template v-if="opened">
      <InputField
        :disabled="!canEditEmail"
        name="email"
        v-model="model.email"
        :error="errors['email']"
        :label="`* ${t('forms.email')}`"
        :placeholder="t('forms.email')"
        type="email"
        data-test-id="emailInput"
      />
      <PhoneInputField
        v-model="model.phone"
        :error="errors['phone']"
        :label="t('forms.phone')"
        :placeholder="t('forms.phone')"
        data-test-id="phoneInput"
      />
      <SelectField
        v-model="model.employmentStatus"
        :label="t('tenant.employmentStatus')"
        :options="employmentStatusOptions"
        :placeholder="t('tenant.employmentStatus')"
        data-test-id="employmentStatusSelector"
      />
      <template v-if="model.employmentStatus">
        <template v-for="documentType in employmentStatusDocumentTypes[model.employmentStatus]">
          <p class="mt-1">
            <b>{{ t(`tenant.documents.${documentType}`) }}</b>
          </p>
          <FileInput
            v-model="model.documents[documentType]"
            type="file"
            mimeTypes="image/png,image/jpg,image/jpeg,application/pdf"
            :name="documentType + model.id"
            :maxFiles="10"
            :instructions="t('tenant.documents.uploadInstructions')"
            :data-test-id="`${documentType}FileInput`"
            @delete="onDeleteDocument(documentType, $event)"
          />
        </template>
      </template>
      <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">{{ errors['generic'] }}</p>
      <Button class="mt-1" variant="secondary" :loading="loading" @click="onSave" data-test-id="saveButton">
        {{ t('common.save') }}
      </Button>
    </template>
    <TenantCardToggle :opened="opened" :tenant="model" @toggle="opened = !opened" />
  </div>
</template>
<script lang="ts" setup>
import { Button, FileInput, InputField, PhoneInputField, SelectField, TenantCardToggle } from '@/components'
import type { Document } from '@/modules/shared/domain/document/document'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { deleteTenantDocument, updateTenant, updateTenantDocuments } from '@/modules/tenant/application'
import { isTenantDocumentationCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { employmentStatusDocumentTypes, TenantDocumentType } from '@/modules/tenant/domain/tenantDocuments'
import { TenantEmploymentStatus } from '@/modules/tenant/domain/tenantEmploymentStatus'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })

defineProps<{ canEditEmail: boolean }>()

defineExpose({ save })

const { t } = useI18n()

const card = ref<Nullable<HTMLElement>>(null)
const loading = ref(false)
const errors = ref<Record<string, string>>({})
const opened = ref(!isTenantDocumentationCompleted(model.value))

const employmentStatusOptions = Object.values(TenantEmploymentStatus).map((value) => ({
  value,
  label: `tenant.employmentStatuses.${value}`
}))

async function onSave() {
  try {
    await save()
  } catch {}
}

async function onDeleteDocument(documentType: TenantDocumentType, documentToDelete: Document) {
  model.value.documents[documentType] = model.value.documents[documentType].filter(
    (document) => document !== documentToDelete
  )
  if (documentToDelete.id) {
    await deleteTenantDocument(model.value.id, documentToDelete.id)
  }
}

function scrollIntoView() {
  if (card.value?.scrollIntoView) {
    card.value.scrollIntoView({ behavior: 'smooth' })
  }
}

async function save() {
  loading.value = true
  errors.value = {}

  try {
    await updateTenant(model.value.id, {
      email: model.value.email,
      name: model.value.name,
      surname: model.value.surname,
      phone: model.value.phone,
      employmentStatus: model.value.employmentStatus
    })
    model.value.documents = await updateTenantDocuments(model.value.id, model.value.documents)
    loading.value = false
  } catch (error) {
    scrollIntoView()
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    loading.value = false
    throw error
  }
}
</script>

import type { AuthService } from '@/modules/auth/domain/authService'
import type { TenantRepository } from '../domain/tenantRepository'
import type { TenantDocuments } from '../domain/tenantDocuments'

export function updateDocumentsBuilder(tenantRepository: TenantRepository, authService: AuthService) {
  return async (tenantId: string, documents: TenantDocuments) => {
    const userId = await authService.getUserId()
    return tenantRepository.updateDocuments(userId, tenantId, documents)
  }
}

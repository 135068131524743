<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  customClass: {
    type: String,
    required: false,
    default: ''
  },
  footerPosition: {
    type: String,
    default: 'fixed', // default position is fixed
    validator: (value: string) => ['fixed', 'absolute'].includes(value)
  }
})

const mainClass = computed(() => {
  return ['layout-wrapper', props.customClass].join(' ')
})

const footerPositionClass = computed(() => {
  return props.footerPosition === 'fixed' ? 'footer-fixed' : 'footer-absolute'
})
</script>

<!-- Layout used for onboarding and auth screens -->
<template>
  <div class="wrapper">
    <div :class="mainClass">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="main">
        <slot name="main"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div :class="[footerPositionClass]">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.layout-wrapper {
  display: flex;
  flex-direction: row;
  padding: $paddingXXL;
  padding-bottom: 2 * $paddingXXL;
  box-sizing: border-box;
  justify-content: center;
  gap: 4rem;

  .left {
    width: 390px;
  }

  .main {
    max-width: 600px;
    width: 100%;
  }

  .right {
    width: 350px;
  }

  @media only screen and (max-width: 1024px) {
    padding: $paddingM;
    padding-bottom: 2 * $paddingL;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: $paddingL;
    padding-bottom: 2 * $paddingL;
    gap: 1rem;

    .left {
      width: 100%;
    }

    .main {
      max-width: unset;
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: $grey100;
}

.footer-absolute {
  position: absolute;
  background-color: $grey100;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}
</style>

export enum AvailabilityType {
  ExactDate = 'exactDate',
  LessThanOneMonth = 'less1month',
  OneToThreeMonths = 'oneTo3months',
  MoreThanThreeMonths = 'more3months'
}

export interface PropertyCoreInformation {
  availabilityDate: Nullable<string>
  availabilityType: Nullable<AvailabilityType>
  builtSize: Nullable<number>
  catastralReference: Nullable<string>
  floor: Nullable<string>
  street: Nullable<string>
  rent: Nullable<number>
  usableSize: Nullable<number>
}

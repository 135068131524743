<template>
  <div class="flex column gap-1">
    <Button variant="secondary" :icon="googleIcon" @click="onSignUpWithGoogle">{{ t('auth.signInWithGoogle') }}</Button>
    <p v-if="errorMessage" class="error-message center">{{ t(errorMessage) }}</p>
  </div>
</template>
<script lang="ts" setup>
import googleIcon from '@/assets/images/g_logo.svg'
import { Button } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication } from '@/modules/application/application'
import { findTenantLead } from '@/modules/tenant/application'
import { signUpWithGoogle } from '@/modules/user/application'
import { UserType } from '@/modules/user/domain/user'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  userType: UserType
  navigateOnSuccess: string
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const errorMessage = ref('')

async function linkTenantApplication() {
  try {
    const { propertyId } = await findTenantLead()
    if (propertyId) {
      await createApplication(propertyId)
    }
  } catch {}
}

async function onSignUpWithGoogle() {
  errorMessage.value = ''

  try {
    await signUpWithGoogle(props.userType)
    if (props.userType === UserType.Tenant) {
      track(AnalyticsEvent.TenantSignupRegisterWithGoogle)
      await linkTenantApplication()
    } else {
      track(AnalyticsEvent.LandlordSignupRegisterWithGoogle)
    }
    router.push({ name: props.navigateOnSuccess })
  } catch (error) {
    errorMessage.value = 'genericError.unexpected'
  }
}
</script>

import type { Document } from '@/modules/shared/domain/document/document'
import type { Tenant } from '../domain/tenant'
import { TenantDocumentType } from '../domain/tenantDocuments'
import type { TenantEmploymentStatus } from '../domain/tenantEmploymentStatus'

export interface TenantDocumentDto {
  id: string
  path: string
  originalName: string
  type: string
}

export interface SubtenantApiDto {
  email: Nullable<string>
  employmentStatus: Nullable<TenantEmploymentStatus>
  id: number
  name: Nullable<string>
  phone: Nullable<string>
  files?: TenantDocumentDto[]
}

export interface TenantApiDto {
  email: Nullable<string>
  employmentStatus: Nullable<TenantEmploymentStatus>
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
  files: TenantDocumentDto[]
  subTenants: SubtenantApiDto[]
}

export function fromFileDto({ id, path, originalName }: TenantDocumentDto): Document {
  const isImage = ['.png', '.jpg', '.jpeg'].some((extension) => originalName.toLowerCase().endsWith(extension))
  return {
    id,
    name: originalName,
    source: null,
    uri: isImage ? `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/userfiles/${path}` : null
  }
}

export function fromFilesDto(files?: TenantDocumentDto[]): Record<TenantDocumentType, Document[]> {
  function mapFileType(type: string, namePrefix?: string, ignorePrefixes?: string[]): Document[] {
    if (!files) return []

    return files
      .filter((file) => file.type === type && (!namePrefix || file.originalName.startsWith(namePrefix)))
      .filter((file) => !ignorePrefixes || !ignorePrefixes.some((prefix) => file.originalName.startsWith(prefix)))
      .map(fromFileDto)
  }

  return {
    employmentContract: mapFileType('employment-contract'),
    id: mapFileType('id'),
    lastForm100: mapFileType('payslip', 'last_form_100'),
    lastForm300: mapFileType('payslip', 'last_form_300'),
    payslip: mapFileType('payslip', undefined, ['last_form_100', 'last_form_300', 'pension_revaluation_certificate']),
    pensionRevaluationCertificate: mapFileType('payslip', 'pension_revaluation_certificate')
  }
}

export function fromDto(dto: TenantApiDto): Tenant[] {
  const mainTenant = {
    email: dto.email,
    employmentStatus: dto.employmentStatus,
    id: dto.id,
    name: dto.name,
    phone: dto.phone,
    surname: dto.surname,
    documents: fromFilesDto(dto.files)
  }

  function nameFromSubtenantDto(dto: Nullable<string>): Nullable<string> {
    if (!dto) return null

    const [name] = dto.split(' ')
    return name
  }

  function surnameFromSubtenantDto(dto: Nullable<string>): Nullable<string> {
    if (!dto) return null

    const [_, ...surname] = dto.split(' ')
    return surname.length > 0 ? surname.join(' ') : null
  }

  const subTenants = dto.subTenants
    .sort((a, b) => a.id - b.id)
    .map((subTenant) => ({
      email: subTenant.email,
      employmentStatus: subTenant.employmentStatus,
      id: subTenant.id.toString(),
      name: nameFromSubtenantDto(subTenant.name),
      phone: subTenant.phone,
      surname: surnameFromSubtenantDto(subTenant.name),
      documents: fromFilesDto(subTenant.files)
    }))

  return [mainTenant, ...subTenants]
}

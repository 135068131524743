import type { AuthService } from '@/modules/auth/domain/authService'
import type { UpdateTenantForm } from '../domain/updateTenantForm'
import type { TenantRepository } from '../domain/tenantRepository'

export function updateBuilder(
  tenantRepository: TenantRepository,
  authService: AuthService,
  validator: Validator<UpdateTenantForm>
) {
  return async (tenantId: string, primitive: Primitive<UpdateTenantForm>) => {
    const form = validator(primitive)
    const userId = await authService.getUserId()
    await tenantRepository.update(userId, tenantId, form)
  }
}

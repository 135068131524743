<template>
  <nav class="navbar-container">
    <div class="left-mobile">
      <MobileMenu :user="user" />
    </div>
    <div class="left">
      <RouterLink :to="{ name: 'home' }">
        <img src="@/assets/images/vivara_logo_dark.svg" />
      </RouterLink>
      <RouterLink v-if="isSignedIn && user" :to="{ name: 'home' }" class="nav-link rounded">
        {{ user.type === UserType.Landlord ? t('properties.myProperties') : t('applications.myApplications') }}
      </RouterLink>
    </div>
    <div class="right">
      <LanguageSelector />
      <ProfileDropdown v-if="isSignedIn && user" :user="user" />
    </div>
    <div class="right-mobile">
      <ProfileDropdown v-if="isSignedIn && user" :user="user" hideName />
    </div>
  </nav>
</template>
<script setup lang="ts">
import { Avatar, LanguageSelector, MobileMenu, ProfileDropdown } from '@/components'
import { findCurrentUser } from '@/modules/user/application'
import { UserType, type User } from '@/modules/user/domain/user'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute } from 'vue-router'

const route = useRoute()

const { t } = useI18n()

const isSignedIn = ref(false)

const user = ref<Nullable<User>>(null)

watch(route, async ({ meta }) => {
  isSignedIn.value = !meta.signedOut
  if (isSignedIn.value) {
    user.value = await findCurrentUser()
  }
})
</script>
<style scoped lang="scss">
.navbar-container {
  height: 60px;
  box-sizing: border-box;
  padding: 0.25rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  .left,
  .right {
    display: flex;
    align-items: inherit;
    gap: 2rem;

    span {
      line-height: 1rem;
    }
    a {
      display: flex;
      align-items: inherit;
      gap: 1rem;
    }
  }

  .left-mobile,
  .right-mobile {
    display: none;
  }

  .nav-link {
    color: $grey500;
    text-decoration: none;
  }
  @media only screen and (max-width: 1024px) {
    height: unset;
    padding: $paddingXS $paddingM;
  }

  @media only screen and (max-width: 768px) {
    .left a {
      display: none;
    }
    .right {
      display: none;
      a {
        display: none;
      }
    }
    .left-mobile,
    .right-mobile {
      display: block;
    }
  }
}
</style>

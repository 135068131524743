<template>
  <div class="input-wrapper">
    <div class="input" :class="{ error: error && error !== '' }">
      <label :for="inputId">{{ label }}</label>
      <input
        class="input-field"
        :placeholder="placeholder"
        :id="inputId"
        :type="inputType ?? 'text'"
        :value="modelValue"
        :disabled="disabled"
        @input="onInput"
        @blur="onBlur"
      />
      <div v-if="type === 'password'" class="password-toggle" @click="togglePasswordVisibility">
        <Icon v-if="!isPasswordVisible" :icon="mdiEyeOff" />
        <Icon v-else :icon="mdiEye" />
      </div>
    </div>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from './Icon.vue'

const props = defineProps<{
  modelValue: Nullable<string>
  label?: string
  error?: string
  type?: string
  placeholder?: string
  disabled?: boolean
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Nullable<string>): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const inputId = Math.random().toString(36).slice(2, 9)

function onInput(event: Event) {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value !== '' ? target.value : null)
}

function onBlur() {
  if (props.modelValue && props.trackEnterEvent) {
    track(props.trackEnterEvent)
  }
}

const isPasswordVisible = ref(false)

const inputType = computed(() => {
  if (props.type === 'password') {
    return isPasswordVisible.value ? 'text' : 'password'
  }
  return props.type
})

function togglePasswordVisibility() {
  isPasswordVisible.value = !isPasswordVisible.value
}
</script>
<style scoped lang="scss">
.input-wrapper {
  flex-grow: 1;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &.error {
    .input-field {
      border: 1px solid red;
    }
  }

  .input-field {
    box-sizing: border-box;
    height: 2.75rem;
    padding: 0.75rem 0.5rem;
    border: 1px solid $lightBorder;
    border-radius: 6px;
    font-size: 1rem;

    &::placeholder {
      color: $grey300;
      font-weight: 300;
      font-size: 0.875rem;
    }
    &:disabled {
      background-color: $grey200;
    }
  }

  .password-toggle {
    position: absolute;
    right: 0.8rem;
    bottom: 0.3rem;
    cursor: pointer;
  }
}
</style>

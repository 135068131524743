export enum PricingPlan {
  Secured = 'secured',
  PeaceOfMind = 'peaceOfMind'
}

export enum PricingPlanFeature {
  TenantReplacement = 'tenantReplacement',
  Photography = 'photography',
  TenantSelection = 'tenantSelection',
  DigitalContract = 'digitalContract',
  AutomaticMonthlyPayments = 'automaticMonthlyPayments',
  NonPaymenetCoverage = 'nonPaymenetCoverage',
  EvictionCoverage = 'evictionCoverage',
  ValdalismCoverage = 'valdalismCoverage',
  RepairManagement = 'repairManagement',
  IncidentManagement = 'incidentManagement',
  DepositManagement = 'depositManagement',
  UtilityTransfers = 'utilityTransfers'
}

export function isFeatureIncludedInPricingPlan(feature: PricingPlanFeature, plan: PricingPlan): boolean {
  const propertyPlanFeatures: Record<PricingPlan, PricingPlanFeature[]> = {
    [PricingPlan.Secured]: [
      PricingPlanFeature.TenantReplacement,
      PricingPlanFeature.Photography,
      PricingPlanFeature.TenantSelection,
      PricingPlanFeature.DigitalContract,
      PricingPlanFeature.AutomaticMonthlyPayments,
      PricingPlanFeature.NonPaymenetCoverage,
      PricingPlanFeature.EvictionCoverage,
      PricingPlanFeature.ValdalismCoverage
    ],
    [PricingPlan.PeaceOfMind]: Object.values(PricingPlanFeature)
  }

  return propertyPlanFeatures[plan].includes(feature)
}

export interface PropertyPaymentInformation {
  plan: Nullable<PricingPlan>
}

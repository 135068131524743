<template>
  <Layout>
    <template #left>
      <PropertyStepper :property="property" :landlord="landlord" />
    </template>
    <template #main>
      <EquipmentInformationForm v-model="equipmentInformation" />
    </template>
    <template #footer>
      <PropertyStepper mobile :property="property" :landlord="landlord" />
      <OnboardingFooter @continue="onContinue" @exit="onExit" :step="3" :isLoading="loading" />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { EquipmentInformationForm, Layout, OnboardingFooter, PropertyStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById, updatePropertyEquipmentInformation } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import { type PropertyEquipmentInformation } from '@/modules/property/domain/propertyEquipmentInformation'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const equipmentInformation = ref<PropertyEquipmentInformation | undefined>()
const loading = ref(false)

track(AnalyticsEvent.LandlordPropertyRegisterStartEquipmentExtras)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  equipmentInformation.value = { ...property.value.equipmentInformation }
})

async function save() {
  if (!equipmentInformation.value) return

  loading.value = true
  try {
    await updatePropertyEquipmentInformation(id, equipmentInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitEquipmentExtras)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'propertyPhotos' })
}
</script>

<script setup lang="ts">
import { Icon } from '@/components'
import { i18n, type Language } from '@/i18n'
import { mdiMenuDown } from '@mdi/js'
import { ref } from 'vue'

const selectedLanguage = ref<Language>(i18n.global.locale.value)
const languages: { code: Language; label: string }[] = [
  { code: 'en', label: 'EN' },
  { code: 'es', label: 'ES' }
]
const handleLanguageChange = (event: Event) => {
  const target = event.target as HTMLSelectElement
  selectedLanguage.value = target.value as Language
  localStorage.setItem('lang', selectedLanguage.value)
  i18n.global.locale.value = selectedLanguage.value
}
</script>
<template>
  <div class="language-selector-wrapper">
    <select :value="selectedLanguage" @change="handleLanguageChange" class="language-selector">
      <option v-for="language in languages" :key="language.code" :value="language.code">
        {{ language.label }}
      </option>
    </select>
    <Icon :icon="mdiMenuDown" size="20" />
  </div>
</template>
<style scoped lang="scss">
.language-selector-wrapper {
  border: 1px solid $grey300;
  border-radius: 20px;
  cursor: pointer;
  position: relative;

  .language-selector {
    background: transparent;
    padding: 0.6rem;
    border: none;
    color: $grey500;
    font-size: 0.875rem;
    appearance: none;
    cursor: pointer;
    width: 3.5rem;

    @media only screen and (max-width: 768px) {
      color: white;
    }
  }

  svg {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    pointer-events: none;
  }
}
</style>

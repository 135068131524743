<template>
  <div class="popular" v-if="popular">{{ t('property.payment.popular') }}</div>
  <div class="plan" :class="{ dramatic: popular, selected }" :data-test-id="`${plan}PlanSelector`">
    <div class="main">
      <div class="title">
        <div class="check" @click="emit('select')"></div>
        <div>
          <h3>{{ t(`property.payment.${plan}.name`) }}</h3>
          <p class="mt-05">{{ t(`property.payment.${plan}.description`) }}</p>
        </div>
      </div>
      <div class="price">{{ t(`property.payment.${plan}.price`) }}</div>
    </div>
    <div class="details" v-if="opened">
      <div v-for="feature in allPricingPlanFeatures">
        <img v-if="isFeatureIncludedInPricingPlan(feature, plan)" src="@/assets/images/icons/check_green.svg" />
        <img v-else src="@/assets/images/icons/cancel_red.svg" />
        <p>{{ t(`property.payment.features.${feature}`) }}</p>
      </div>
    </div>
    <div class="more-details" @click="opened = !opened">
      <span v-if="opened">{{ t('property.payment.lessDetails') }}</span>
      <span v-else>{{ t('property.payment.moreDetails') }}</span>
      <img v-if="opened" src="@/assets/images/icons/chevron_up.svg" />
      <img v-else src="@/assets/images/icons/chevron_down.svg" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  isFeatureIncludedInPricingPlan,
  PricingPlan,
  PricingPlanFeature
} from '@/modules/property/domain/propertyPaymentInformation'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps<{
  plan: PricingPlan
  selected?: boolean
  popular?: boolean
}>()

const emit = defineEmits(['select'])

const { t } = useI18n()

const opened = ref(false)
const allPricingPlanFeatures = Object.values(PricingPlanFeature)
</script>
<style lang="sass" scoped>
@keyframes appear
  from
    opacity: 0
    transform: scale(0.95)
  to
    opacity: 1
    transform: scale(1)

.popular
  box-sizing: border-box
  background-color: #0B0B0B
  border-top-right-radius: 1.25rem
  border-top-left-radius: 1.25rem
  text-align: right
  padding: 1rem
  padding-bottom: 2rem
  position: relative
  z-index: 0
  top: 1rem
  margin-top: 1rem
  left: 0
  width: 100%
  color: white

.plan
  position: relative
  z-index: 1
  padding: 2rem 1.25rem
  border-radius: 1.25rem
  border: 1px solid #F0F0F0
  background-color: white

  .main
    display: flex
    align-items: center
    gap: 1rem

    @media only screen and (max-width: 768px)
      flex-direction: column
      align-items: flex-start

  .title
    display: flex
    gap: 1rem
    width: 100%
    padding-right: 1rem

    @media only screen and (min-width: 768px)
      border-right: 1px solid #F0F0F0

    p
      font-size: 1rem
      font-weight: bold

    .check
      flex-shrink: 0
      width: 1rem
      height: 1rem
      border-radius: 50%
      border: 2px solid #0B0B0B
      margin-top: 0.25rem
      cursor: pointer
      transition: transform 0.15s

      &:hover
        transform: scale(1.1)

  .details
    margin-top: 2rem
    animation: appear 0.25s ease

    > div
      display: flex
      gap: 1rem
      align-items: center

      p
        font-size: 1rem

    @media only screen and (min-width: 768px)
      margin-left: 2.25rem

  .more-details
    margin-top: 2rem
    cursor: pointer

    @media only screen and (min-width: 768px)
      margin-left: 2.25rem

    span
      margin-right: 0.5rem

    img
      transform: translateY(-1px)

    &:hover
      text-decoration: underline

  &.selected
    background-color: #F6F3A0

    .check::after
      content: ''
      width: 0.6rem
      height: 0.6rem
      border-radius: 50%
      background-color: #0B0B0B
      display: block
      position: relative
      top: 0.2rem
      left: 0.2rem

    .title
      border-color: #dbd872

  &.dramatic
    border-color: transparent
    box-shadow: 0px 9px 12px 0px #0B0B0B33
</style>

<template>
  <div class="phone-number-wrapper">
    <label>{{ label }}</label>
    <div class="selector-wrapper">
      <select class="selector" :value="code" @change="handleCodeChange">
        <option v-for="option in options" :key="option.code" :value="option.code">
          {{ option.flag }} {{ option.dial_code }}
        </option>
      </select>
      <NumberField
        type="number"
        :modelValue="phoneNumber"
        :placeholder="placeholder"
        :trackEnterEvent="trackEnterEvent"
        @update:modelValue="onPhoneNumberChange"
      />
    </div>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
import CountryList from 'country-list-with-dial-code-and-flag'
import { onMounted, ref } from 'vue'
import NumberField from './NumberField.vue'
import { useI18n } from 'vue-i18n'
import type { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'

const { t } = useI18n()

const defaultCountryCode = 'ES'
const defaultCountry = CountryList.findOneByCountryCode(defaultCountryCode)
const options = CountryList.getAll().filter(({ code }) => code === defaultCountryCode)
const code = ref<string | undefined>(defaultCountry?.code)
const dialCode = ref(CountryList.findOneByCountryCode(code.value || defaultCountryCode)?.dial_code)
const phoneNumber = ref<Nullable<number>>(null)

const props = defineProps<{
  label: string
  modelValue: Nullable<string>
  error?: string
  placeholder?: string
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
}>()

onMounted(() => {
  if (props.modelValue) {
    const countryCode = options.find((option) => props.modelValue?.startsWith(option.dial_code))
    if (countryCode) {
      code.value = countryCode.code
      dialCode.value = countryCode.dial_code
      phoneNumber.value = parseInt(props.modelValue.slice(countryCode.dial_code.length))
    }
  }
})

function handleCodeChange(event: Event) {
  const target = event.target as HTMLSelectElement
  code.value = target.value
  dialCode.value = CountryList.findOneByCountryCode(target.value)?.dial_code
  emit('update:modelValue', phoneNumber.value ? `${dialCode.value}${phoneNumber.value}` : null)
}

function onPhoneNumberChange(value: Nullable<number>) {
  phoneNumber.value = value
  emit('update:modelValue', phoneNumber.value ? `${dialCode.value}${phoneNumber.value}` : null)
}
</script>
<style scoped lang="scss">
.phone-number-wrapper {
  label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .selector-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: stretch;
    gap: 0.5rem;

    .selector {
      flex-grow: 0.1;
      padding: 0.75rem 0.5rem;
      border: 1px solid $lightBorder;
      border-radius: 6px;
      font-size: 1rem;
      flex-shrink: 0;

      appearance: none;
      background-image: url('@/assets/images/icons/select_dropdown.svg');
      background-repeat: no-repeat;
      background-position: right 0.7rem top 50%;
      background-size: 0.65rem auto;
    }
  }
}

:deep() {
  // hide the arrows in the input selector
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
</style>

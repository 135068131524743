import { authService } from '@/modules/auth'
import { landlordDocumentsValidator, landlordRepository } from '../infrastructure'
import { deleteDocumentBuilder } from './deleteDocument'
import { findCurrentBuilder } from './findCurrent'
import { updateDocumentsBuilder } from './updateDocuments'

export const deleteCurrentLandlordDocument = deleteDocumentBuilder(authService, landlordRepository)
export const findCurrentLandlord = findCurrentBuilder(authService, landlordRepository)
export const updateCurrentLandlordDocuments = updateDocumentsBuilder(
  authService,
  landlordRepository,
  landlordDocumentsValidator
)

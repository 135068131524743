import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { boolean, object, string } from 'yup'
import type { SignUpForm } from '../domain/signUpForm'
import { UserType } from '../domain/user'

export function yupSignUpFormValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<SignUpForm>({
      email: string()
        .test(
          'is-valid-email',
          'validationError.formatEmail',
          (value) => !value || /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/.test(value)
        )
        .required('validationError.requiredEmail'),
      password: string().required('validationError.requiredPassword').min(6, 'validationError.formatPassword'),
      termsAndConditions: boolean().required().isTrue('validationError.requiredTermsAndConditions'),
      type: string().required().oneOf(Object.values(UserType), 'validationError.required')
    })
  )
}

import type { AuthService } from '@/modules/auth/domain/authService'
import type { SignUpForm } from '../domain/signUpForm'
import type { UserRepository } from '../domain/userRepository'

export function signUpBuilder(
  userRepository: UserRepository,
  authService: AuthService,
  validator: Validator<SignUpForm>
) {
  return async (primitive: Primitive<SignUpForm>) => {
    const form = validator(primitive)
    await userRepository.create(form.type, form.email, form.password)
    await authService.signInWithPassword(form.email, form.password)
  }
}

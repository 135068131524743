import type { AuthService } from '@/modules/auth/domain/authService'
import type { LandlordDocuments } from '../domain/landlordDocuments'
import type { LandlordRepository } from '../domain/landlordRepository'

export function updateDocumentsBuilder(
  authService: AuthService,
  landlordRepository: LandlordRepository,
  validator: Validator<LandlordDocuments>
) {
  return async (primitive: Primitive<LandlordDocuments>) => {
    const documents = validator(primitive)
    const userId = await authService.getUserId()
    await landlordRepository.updateDocuments(userId, documents)
  }
}

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  browserLocalPersistence,
  getAuth,
  getIdToken,
  getIdTokenResult,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  type User
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import type { AuthService } from '../domain/authService'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID
}

export function firebaseAuthServiceBuilder(): AuthService {
  const firebaseApp = initializeApp(firebaseConfig)
  const auth = getAuth(firebaseApp)
  setPersistence(auth, browserLocalPersistence)

  let userId: string | null = null

  function getUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe()
          resolve(user)
        },
        reject
      )
    })
  }

  return {
    async signInWithPassword(email: string, password: string) {
      await signInWithEmailAndPassword(auth, email, password)
    },

    async signInWithFacebook() {
      const provider = new FacebookAuthProvider()
      provider.setCustomParameters({ display: 'popup' })
      const response = await signInWithPopup(auth, provider)
      if (response.user.email === null) {
        await signOut(auth)
        throw new Error('authError.missingEmail')
      } else {
        return response.user.email
      }
    },

    async signInWithGoogle() {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ display: 'popup' })
      const response = await signInWithPopup(auth, provider)
      if (response.user.email === null) {
        await signOut(auth)
        throw new Error('authError.missingEmail')
      } else {
        return response.user.email
      }
    },

    signOut() {
      userId = null
      return signOut(auth)
    },

    async getUserToken(): Promise<string | null> {
      const user = await getUser()
      return user ? getIdToken(user) : null
    },

    async getUserId() {
      if (userId) {
        return userId
      }

      const user = await getUser()
      if (!user) {
        throw new Error('authError.signedOut')
      }

      const idTokenResult = await getIdTokenResult(user, true)
      if (!idTokenResult.claims.id) {
        throw new Error('authError.missingUserId')
      }

      userId = idTokenResult.claims.id as string
      return userId
    },

    async updatePassword(newPassword: string) {
      const user = await getUser()
      if (user) {
        updatePassword(user, newPassword)
      } else {
        throw new Error('No user found on firebase')
      }
    }
  }
}

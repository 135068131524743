<template>
  <Modal v-if="opened" @close="onClose">
    <div class="flex align-center justify-center column gap-1 p-4 primary">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('applications.accountSuccess.title') }}</h2>
      <p class="pl-2 pr-2">
        {{ t('applications.accountSuccess.description') }}
      </p>
      <p>
        <b>{{ t('applications.accountSuccess.question') }}</b>
      </p>
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <RouterLink :to="{ name: 'application', params: { id: application.id } }" @click="onContinue">
        <Button>{{ t('common.continue') }}</Button>
      </RouterLink>
      <a @click="onClose"> {{ t('applications.completeLater') }} </a>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import type { Application } from '@/modules/application/domain/application'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps<{
  application: Application
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const signupCompleted = router.currentRoute.value.query['accountSuccess'] !== undefined
const opened = ref(signupCompleted)

if (signupCompleted) {
  track(AnalyticsEvent.TenantSignupCompleted)
}

function onContinue() {
  track(AnalyticsEvent.TenantSignupContinue)
}

function onClose() {
  track(AnalyticsEvent.TenantSignupContinueLater)
  opened.value = false
}
</script>

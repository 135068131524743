<template>
  <Layout>
    <template #left>
      <PropertyStepper :property="property" :landlord="landlord" />
    </template>
    <template #main>
      <PropertyImagesForm v-model="images" :id="id" />
    </template>
    <template #footer>
      <PropertyStepper mobile :property="property" :landlord="landlord" />
      <OnboardingFooter @continue="onContinue" @exit="onExit" :step="4" :isLoading="loading" />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { Layout, OnboardingFooter, PropertyImagesForm, PropertyStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById, updatePropertyImages } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyImages } from '@/modules/property/domain/propertyImages'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const images = ref<PropertyImages | undefined>()

const loading = ref(false)

const { track } = useAnalytics()

track(AnalyticsEvent.LandlordPropertyRegisterStartImages)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  images.value = { ...property.value.images }
})

async function save() {
  if (!images.value) return

  loading.value = true
  try {
    await updatePropertyImages(id, images.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitImages)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'propertyDocuments' })
}
</script>

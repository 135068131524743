<template>
  <Layout>
    <template #left>
      <SignupStepper :step="1" />
    </template>
    <template #main>
      <div class="flex column gap-1">
        <div>
          <h2 class="mb-0">{{ t('signup.welcome') }}</h2>
          <Title path="signup.type.title" />
        </div>
        <BlobSelector
          data-test-id="landlordTypeSelector"
          :title="t('signup.type.aLandlord')"
          :subtitle="t('signup.type.aLandlordSub')"
          @click="ChooseType(UserType.Landlord)"
        />
        <BlobSelector
          data-test-id="tenantTypeSelector"
          :title="t('signup.type.aTenant')"
          :subtitle="t('signup.type.aTenantSub')"
          @click="ChooseType(UserType.Tenant)"
        />
      </div>
    </template>
    <template #footer>
      <SignupStepper :step="1" mobile />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { BlobSelector, Layout, Title } from '@/components'
import { SignupStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { UserType } from '@/modules/user/domain/user'
import { router } from '@/router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

track(AnalyticsEvent.SignUpStart)

function ChooseType(type: UserType) {
  track(
    type === UserType.Landlord ? AnalyticsEvent.LandlordSignupSelectUserType : AnalyticsEvent.TenantSignupSelectUserType
  )
  router.push({ name: 'accountInformation', params: { type } })
}
</script>

<template>
  <div class="card p-1 align-center" data-test-id="addTenantButton">
    <Loader v-if="loading" />
    <template v-else>
      <img src="@/assets/images/icons/add_person.svg" />
      <p>{{ t('tenant.add') }}</p>
    </template>
  </div>
</template>
<script setup lang="ts">
import { Loader } from '@/components'
import { useI18n } from 'vue-i18n'

defineProps<{ loading?: boolean }>()

const { t } = useI18n()
</script>
<style scoped lang="sass">
.card
  background-color: #FFFDC9
  cursor: pointer

  &:hover
    background-color: #F6F3A0
</style>

import {
  PropertyPhotos,
  PropertyDocuments,
  CoreInformation,
  BasicInformation,
  EquipmentInformation,
  PaymentInformation
} from '@/views'
import ResumeOnboarding from '@/views/property/ResumeOnboarding.vue'
import type { RouteRecordRaw } from 'vue-router'

export const propertyRoutes: RouteRecordRaw = {
  path: '/property/:id',
  name: 'property',
  meta: { landlord: true },
  children: [
    {
      path: 'information',
      name: 'coreInformation',
      component: CoreInformation
    },
    {
      path: 'basic',
      name: 'basicInformation',
      component: BasicInformation
    },
    {
      path: 'equipment',
      name: 'equipmentInformation',
      component: EquipmentInformation
    },
    {
      path: 'photos',
      name: 'propertyPhotos',
      component: PropertyPhotos
    },
    {
      path: 'documents',
      name: 'propertyDocuments',
      component: PropertyDocuments
    },
    {
      path: 'plan',
      name: 'paymentInformation',
      component: PaymentInformation
    },
    {
      path: 'resume',
      name: 'resumeOnboarding',
      component: ResumeOnboarding
    }
  ]
}

<script setup lang="ts">
import { Button } from '@/components'

defineProps<{
  name?: string | null
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'addNewProperty'): void
}>()
</script>

<template>
  <div class="add-first-property">
    <div class="content">
      <h2>{{ $t('addFirstProperty.intro', { name: name }) }}</h2>
      <p>{{ $t('addFirstProperty.sub') }}</p>
      <img src="@/assets/images/myProperties/Illustration.svg" />
    </div>
    <Button :loading="isLoading" @click="emit('addNewProperty')">{{ $t('addFirstProperty.addProperty') }} </Button>
  </div>
</template>
<style scoped lang="scss">
.add-first-property {
  display: flex;
  flex-direction: column;
  .content {
    padding: $paddingM;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid $grey200;
    border-radius: 1.25rem;
    text-align: center;
    img {
      margin-top: 1.5rem;
      max-width: 450px;
      width: 100%;
    }
  }
}
</style>

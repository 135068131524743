<template>
  <Icon :icon="mdiMenu" size="24" @click="onToggle" class="hamburger-menu-icon" />
  <div class="sidebar" :class="{ opened }">
    <div class="top-section">
      <LanguageSelector />
      <Icon :icon="mdiClose" size="24" @click="onToggle" class="hamburger-menu-icon" />
    </div>
    <RouterLink v-if="user" :to="{ name: 'home' }" class="nav-link rounded">
      {{ user.type === UserType.Landlord ? t('properties.myProperties') : t('applications.myApplications') }}
    </RouterLink>
  </div>
</template>
<script setup lang="ts">
import { UserType, type User } from '@/modules/user/domain/user'
import { mdiClose, mdiMenu } from '@mdi/js'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from '../ui/Icon.vue'
import LanguageSelector from '../ui/LanguageSelector.vue'

defineProps<{ user: Nullable<User> }>()

const { t } = useI18n()

const opened = ref(false)
function onToggle() {
  opened.value = !opened.value
}
</script>
<style scoped lang="scss">
.hamburger-menu-icon {
  cursor: pointer;
}

.sidebar {
  transition: all 0.2s ease;
  background-color: $primaryColor;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 0;
  width: 75%;
  height: 100%;
  z-index: 11;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: $paddingL 0;
  box-sizing: border-box;
  gap: 0.5rem;
  .top-section {
    display: flex;
    justify-content: space-between;
    color: white;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .nav-link {
    color: white;
    text-decoration: none;
  }
  &.opened {
    max-width: 75vw;
    box-shadow: 0 0 25vw $primaryColor;
    padding: $paddingL;
  }
}
</style>

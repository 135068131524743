export enum UserType {
  Landlord = 'landlord',
  Tenant = 'tenant'
}

export interface User {
  email: string
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
  type: UserType
}

<template>
  <a v-if="userType === UserType.Tenant" :href="tenantTermsAndConditionsUrl" target="_blank">
    {{ t('auth.termsAndConditions') }}
  </a>
  <a v-else id="terms-conditions" :href="landlordTermsAndConditionsUrl" target="_blank">
    {{ t('auth.termsAndConditions') }}
  </a>
  {{ t('common.and') }}
  <a id="privacy-policy" :href="privacyPolicyUrl" target="_blank">
    {{ t('auth.privacyPolicy') }}
  </a>
</template>
<script lang="ts" setup>
import { i18n } from '@/i18n'
import { UserType } from '@/modules/user/domain/user'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps<{
  userType: UserType
}>()

const { t } = useI18n()

const landlordTermsAndConditionsUrl = import.meta.env.VITE_LANDLORD_TERMS_AND_CONDITIONS_URL
const tenantTermsAndConditionsUrl = import.meta.env.VITE_TENANT_TERMS_AND_CONDITIONS_URL
const privacyPolicyUrl = computed(() =>
  i18n.global.locale.value === 'en'
    ? import.meta.env.VITE_PRIVACY_POLICY_URL_EN
    : import.meta.env.VITE_PRIVACY_POLICY_URL_ES
)
</script>

import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { array, object } from 'yup'
import type { PropertyDocuments } from '../domain/propertyDocuments'

export function yupPropertyDocumentsValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyDocuments>({
      habitabilityCertificate: array().nullable(),
      utilityBill: array().nullable(),
      energyCertificate: array().nullable()
    })
  )
}

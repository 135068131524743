import type { Landlord } from '../domain/landlord'

export interface LandlordApiDto {
  files: {
    type: string
    id: string
    originalName: string
    path?: string
  }[]
}

export function fromDto(dto: LandlordApiDto): Landlord {
  return {
    documents: {
      id:
        dto.files
          ?.filter(({ type }) => type === 'id')
          .map(({ id, originalName, path }) => ({
            name: originalName,
            id,
            uri: path ? `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/userfiles/${path}` : null,
            source: null
          })) ?? []
    }
  }
}

<template>
  <div class="flex column gap-1" v-if="model">
    <div v-for="documentType in documentTypes" :id="documentType">
      <p>
        <b>{{ t(`property.documents.type.${documentType}`) }}</b>
      </p>
      <div class="card">
        <FileInput
          v-model="model[documentType]"
          type="file"
          mimeTypes="image/png,image/jpg,image/jpeg,application/pdf"
          :name="documentType"
          :maxFiles="10"
          :instructions="t('property.documents.uploadInstructions')"
          :trackSelectEvent="uploadEvents[documentType]"
          :trackInvalidFormatEvent="AnalyticsEvent.LandlordPropertyRegisterErrorDocumentationFormat"
          :data-test-id="`${documentType}FileInput`"
          @delete="onDelete(documentType, $event)"
        />
      </div>
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FileInput, FormSkeleton } from '@/components'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { deleteCurrentLandlordDocument } from '@/modules/landlord/application'
import type { LandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import type { Document } from '@/modules/shared/domain/document/document'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const model = defineModel<LandlordDocuments>()

const documentTypes: (keyof LandlordDocuments)[] = ['id']

const uploadEvents: Record<keyof LandlordDocuments, AnalyticsEvent> = {
  id: AnalyticsEvent.LandlordDocumentationUploadId
}

async function onDelete(documentType: keyof LandlordDocuments, documentToDelete: Document) {
  if (!model.value) return

  model.value[documentType] = model.value[documentType].filter((document) => document !== documentToDelete)
  if (documentToDelete.id) {
    await deleteCurrentLandlordDocument(documentToDelete.id)
  }
}
</script>

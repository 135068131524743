import { i18n } from '@/i18n'
import type { AxiosInstance } from 'axios'
import type { UpdateUserForm } from '../domain/updateUserForm'
import { UserType, type User } from '../domain/user'
import type { UserRepository } from '../domain/userRepository'
import type { UserApiDto } from './apiDto'

export function apiUserRepositoryBuilder(axios: AxiosInstance): UserRepository {
  let cache: Nullable<User> = null
  let cacheId: Nullable<string> = null

  return {
    async create(type: UserType, email: string, password?: string) {
      if (type === UserType.Landlord) {
        await axios.post('/landlords', { email, password })
      } else {
        await axios.post('/tenants', { email, password })
      }
    },
    async delete({ id, type }: User) {
      if (type === UserType.Landlord) {
        await axios.delete(`/landlords/${id}`)
      } else {
        await axios.delete(`/tenants/${id}`)
      }
    },
    async get(id: string) {
      if (id === cacheId && cache !== null) {
        return cache
      }

      try {
        const dto = (await axios.get(`/landlords/${id}`)) as UserApiDto
        cache = { ...dto, type: UserType.Landlord }
      } catch {
        const dto = (await axios.get(`/tenants/${id}`)) as UserApiDto
        cache = { ...dto, type: UserType.Tenant }
      }

      cacheId = id
      return cache
    },
    async resetPassword(token: string, password: string, type: UserType) {
      if (type === UserType.Landlord) {
        await axios.post(`/landlords/reset-password`, { token, password })
      } else {
        await axios.post(`/tenants/reset-password`, { token, password })
      }
    },
    async sendPasswordRecoveryEmail(email: string) {
      const lang = i18n.global.locale.value
      try {
        await axios.post(`/landlords/forgot-password`, { email, lang })
      } catch {
        await axios.post(`/tenants/forgot-password`, { email, lang })
      }
    },
    async update(id: string, form: UpdateUserForm) {
      if (id === cacheId && cache !== null) {
        cache = { ...cache, ...form }
      }

      const { name, phone, type, surname } = form
      const lang = i18n.global.locale.value

      if (type === UserType.Landlord) {
        await axios.patch(`/landlords/${id}?lang=${lang}`, { name, phone, surname })
      } else {
        await axios.patch(`/tenants/${id}`, { name, phone, surname })
      }
    }
  }
}

import { employmentStatusDocumentTypes, type TenantDocuments } from './tenantDocuments'
import type { TenantEmploymentStatus } from './tenantEmploymentStatus'

export interface Tenant {
  documents: TenantDocuments
  email: Nullable<string>
  employmentStatus: Nullable<TenantEmploymentStatus>
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
}

export function isTenantDocumentationCompleted(tenant: Tenant) {
  if (!tenant.employmentStatus) {
    return false
  }

  const areDocumentsCompleted = employmentStatusDocumentTypes[tenant.employmentStatus].every(
    (documentType) => tenant.documents[documentType].length > 0
  )
  return areDocumentsCompleted
}

export function isTenantCompleted(tenant: Tenant) {
  if (!tenant.email || !tenant.name || !tenant.surname) {
    return false
  }

  return isTenantDocumentationCompleted(tenant)
}

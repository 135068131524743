import Hotjar from '@hotjar/browser'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import { router } from './router'

if (import.meta.env.PROD) {
  const hotjarVersion = 6
  Hotjar.init(import.meta.env.VITE_HOTJAR_ID, hotjarVersion)
}

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.mount('#app')

<template>
  <div id="app">
    <FullScreenLoader v-if="loading" />
    <Navbar />
    <div class="content">
      <RouterView />
    </div>
    <InfoFooter />
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { RouterView } from 'vue-router'
import { FullScreenLoader, InfoFooter, Navbar } from './components'
import { findCurrentUser } from './modules/user/application'

const loading = ref(true)

onMounted(async () => {
  try {
    await findCurrentUser()
  } catch {}
  loading.value = false
})
</script>
<style scoped lang="sass">
#app
  position: relative
  min-height: 100vh

  .content
    padding-bottom: 4rem

    @media only screen and (max-width: 768px)
      padding-bottom: 8rem
</style>

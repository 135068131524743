import { useAnalytics } from '@/hooks/useAnalytics'
import { findCurrentUser } from '@/modules/user/application'
import { UserType } from '@/modules/user/domain/user'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export async function authenticationGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const requiresBeingSignedOut = to.matched.some((record) => record.meta.signedOut)
  const requiresBeingLandlord = to.matched.some((record) => record.meta.landlord)
  const requiresBeingTenant = to.matched.some((record) => record.meta.tenant)

  const { identify } = useAnalytics()

  try {
    const { email, type } = await findCurrentUser()
    identify(email, type)
    if ((requiresBeingSignedOut || requiresBeingTenant) && type === UserType.Landlord) {
      return next({ name: 'properties' })
    } else if ((requiresBeingSignedOut || requiresBeingLandlord) && type === UserType.Tenant) {
      return next({ name: 'applications' })
    } else {
      return next()
    }
  } catch {
    if (!requiresBeingSignedOut) {
      return next({ name: 'signIn' })
    }
  }
  return next()
}

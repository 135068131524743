<template>
  <div class="main">
    <img src="@/assets/images/applications.png" />
    <h2>{{ t('applications.empty') }}</h2>
    <div class="links">
      <a
        :href="idealistaLink"
        target="_blank"
        data-test-id="idealistaLink"
        @click="track(AnalyticsEvent.TenantApplicationSelectIdealista)"
      >
        <Button>Idealista</Button>
      </a>
      <a
        :href="fotocasaLink"
        target="_blank"
        data-test-id="fotocasaLink"
        @click="track(AnalyticsEvent.TenantApplicationSelectFotocasa)"
      >
        <Button>Fotocasa</Button>
      </a>
      <img src="@/assets/images/curvedArrow.png" height="85" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Button } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const idealistaLink = 'https://www.idealista.com/pro/vivara/alquiler-viviendas'
const fotocasaLink =
  'https://www.fotocasa.es/es/inmobiliaria-vivara/comprar/inmuebles/espana/todas-las-zonas/l?clientId=9202772107130'
</script>
<style scoped lang="sass">
.main
  display: flex
  flex-direction: column
  align-items: center
  gap: 4rem

  h2
    text-align: center

  .links
    position: relative
    text-align: center

    a
      text-decoration: none

    .button
      display: block
      margin-bottom: 1rem
      width: 15rem

    img
      position: absolute
      left: -6rem
      top: -3rem
      transform: rotate(-20deg)

      @media only screen and (max-width: 768px)
        display: none
</style>

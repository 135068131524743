import { apiService } from '@/modules/shared/infrastructure/api/axiosApiService'
import { apiUserRepositoryBuilder } from './apiUserRepository'
import { yupSendPasswordRecoveryEmailFormValidatorBuilder } from './yupSendPasswordRecoveryEmailFormValidator'
import { yupSignInFormValidatorBuilder } from './yupSignInFormValidator'
import { yupSignUpFormValidatorBuilder } from './yupSignUpFormValidator'
import { yupUpdatePasswordFormValidatorBuilder } from './yupUpdatePasswordFormValidator'
import { yupUpdateUserFormValidatorBuilder } from './yupUpdateUserFormValidator'

export const userRepository = apiUserRepositoryBuilder(apiService)

export const sendPasswordRecoveryEmailFormValidator = yupSendPasswordRecoveryEmailFormValidatorBuilder()
export const signInFormValidator = yupSignInFormValidatorBuilder()
export const signUpFormValidator = yupSignUpFormValidatorBuilder()
export const updatePasswordFormValidator = yupUpdatePasswordFormValidatorBuilder()
export const updateUserFormValidator = yupUpdateUserFormValidatorBuilder()

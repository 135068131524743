import type { AxiosInstance } from 'axios'
import type { Landlord } from '../domain/landlord'
import type { LandlordRepository } from '../domain/landlordRepository'
import { fromDto, type LandlordApiDto } from './apiDto'
import type { LandlordDocuments } from '../domain/landlordDocuments'

export function apiLandlordRepositoryBuilder(
  apiService: AxiosInstance,
  apiV2Service: AxiosInstance
): LandlordRepository {
  let cache: Nullable<Landlord> = null
  let cacheId: Nullable<string> = null

  return {
    async deleteDocument(userId: string, documentId: string) {
      cache = cacheId = null
      await apiService.delete(`/landlords/${userId}/documents/${documentId}`)
    },
    async get(userId: string) {
      if (userId === cacheId && cache !== null) {
        return cache
      }

      const dto = (await apiService.get(`/landlords/${userId}`)) as LandlordApiDto
      cache = fromDto(dto)
      cacheId = userId
      return cache
    },
    async updateDocuments(userId: string, documents: LandlordDocuments) {
      cache = cacheId = null

      const filesToUpload = documents.id.filter(({ source }) => source !== null).map(({ source }) => source as File)
      for (const file of filesToUpload) {
        const formData = new FormData()
        formData.append('file', file)

        await apiV2Service.post(`/onboarding/landlords/me/files/personal-id`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      }
    }
  }
}
